import { useContext, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Badge,
  styled,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { slide as BurgerMenu } from "react-burger-menu";
import { styles } from "../styles";
import {
  AccountsIcon,
  CollectionsMobileIcon,
  EmptyProfile,
  ExternalLinkIcon,
  HelpCircleIcon,
  LogoutIcon,
  MenuIcon,
  MiniLogoutIcon,
  PayanaLogo,
  PaymentsIcon,
  PayrollIcon,
  SettingsIcon,
  SwitchIcon,
  UserCheckIcon,
  ServiceIcon,
} from "../../../../assets";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../contexts";
import { Box } from "@mui/system";
import {
  getFromLocalStorage,
  logOut,
  saveInLocalStorage,
} from "../../../../utils";
import { CustomLink } from "../../components";
import { useChat } from "../../../../hooks";
import { AccountItem } from "./AccountItem";

const ITEM_HEIGHT = 64;

export const AccountMenu = () => {
  const [menuOpen, setMenuOpen] = useState(null);
  const {
    currentUser,
    currentCompany,
    setIsCompanySwitchModalVisible,
    blockedCompany,
    hasTransactionsLimit,
  } = useContext(UserContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { chatHidden } = useChat();
  const openMenu = getFromLocalStorage("openMenu") || false;

  const open = Boolean(menuOpen);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
    !openMenu && saveInLocalStorage("openMenu", true);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleLogout = () => {
    chatHidden();
    logOut();
    window.location = "/login";
  };

  const menuOptions = [
    {
      title: "FAQS",
      icon: <HelpCircleIcon style={styles.iconMargin} />,
      endIcon: <ExternalLinkIcon style={styles.iconMargin} />,
      action: () => {
        window.open("https://intercom.help/payana-ayuda/es/", "_blank");
      },
    },
    {
      title: "Configuración",
      icon: <SettingsIcon style={styles.iconMargin} />,
      action: () => {
        handleClose();
        return navigate("/settings", { replace: true });
      },
    },
    {
      title: "Cerrar sesión",
      icon: <MiniLogoutIcon style={styles.iconMargin} />,
      action: handleLogout,
    },
  ];

  const mobilePaymentsMenuOptions = [
    {
      title: "Cuentas por pagar",
      action: "/payments/invoices",
    },
    {
      title: "Agenda",
      action: "/payments/suppliers",
    },
  ];

  const mobilePayrollMenuOptions = [
    {
      title: "Pago de nómina",
      action: "/payroll/salaries",
    },
    {
      title: "Empleados",
      action: "/payroll/employees",
    },
  ];

  const mobileCollectionsMenuOptions = [
    {
      title: "Cuentas por cobrar",
      action: "/collections/index",
    },
  ];

  mobileCollectionsMenuOptions.push({
    title: "Agenda",
    action: "/collections/customers",
  });

  const mobileServicesMenuOptions = [
    {
      title: "Cuentas por pagar",
      action: "/services/payments",
    },
    {
      title: "Lotes",
      action: "/services/transactions",
    },
    {
      title: "Agenda",
      action: "/services/agenda",
    },
  ];

  const mobileBOMenuOptions = [
    {
      title: "Soportes",
      action: "backoffice-receipts",
    },
    {
      title: "Soportes-Servicios",
      action: "backoffice-receipts-services",
    },
  ];

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      padding: "0 4px",
      backgroundColor: "red",
      height: "15px",
      minWidth: "15px",
      display: openMenu ? "none" : "block",
    },
  }));

  const accountItems = [
    {
      label: "Proveedores",
      icon: <PaymentsIcon />,
      menuOptions: mobilePaymentsMenuOptions,
    },
    {
      label: "Nómina",
      icon: <PayrollIcon />,
      menuOptions: mobilePayrollMenuOptions,
    },
    {
      label: "Cobros",
      icon: <CollectionsMobileIcon />,
      menuOptions: mobileCollectionsMenuOptions,
    },
    {
      label: "Servicios",
      icon: <ServiceIcon />,
      menuOptions: mobileServicesMenuOptions,
    },
  ];

  return (
    <div className="navDiv" style={{ paddingRight: isMobile ? "0px" : "20px" }}>
      <Button
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {isMobile ? (
          <div style={{ width: 20 }}>
            <MenuIcon />
          </div>
        ) : (
          <>
            <StyledBadge color="primary" overlap="circular" badgeContent="">
              {currentCompany?.file_path_logo ? (
                <img
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                  }}
                  src={currentCompany?.file_path_logo}
                  alt="companyLogo"
                />
              ) : (
                <EmptyProfile />
              )}
            </StyledBadge>
            <Typography sx={styles.account} noWrap>
              {currentCompany?.name || currentUser.name}
            </Typography>
          </>
        )}
      </Button>

      {isMobile ? (
        <BurgerMenu
          right
          isOpen={menuOpen}
          onClose={handleClose}
          pageWrapId={"page-wrap"}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "white",
              padding: "16px",
              width: "100%",
            }}
          >
            <PayanaLogo />
          </div>
          {currentUser.backoffice_user ? (
            <div style={{ paddingLeft: "24px" }}>
              {mobileBOMenuOptions?.map((menuOption) => (
                <CustomLink
                  key={menuOption.title}
                  onClick={handleClose}
                  to={menuOption.action}
                >
                  {menuOption.title}
                </CustomLink>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: "80px" }}>
              {accountItems?.map((accountItem) => (
                <AccountItem
                  label={accountItem.label}
                  icon={accountItem.icon}
                  menuOptions={accountItem.menuOptions}
                  handleClose={handleClose}
                />
              ))}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
              height: "80px",
              alignItems: "center",
              marginLeft: "16px",
              marginRight: "16px",
              justifyContent: "space-between",
              borderTop: "1px solid #EAECF0",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {currentCompany?.file_path_logo ? (
                <img
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                  }}
                  src={currentCompany?.file_path_logo}
                  alt="companyLogo"
                />
              ) : (
                <EmptyProfile />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "16px",
                }}
              >
                <Typography sx={styles.companyName}>
                  {currentCompany?.name?.toUpperCase() ||
                    currentUser.name?.toUpperCase()}
                </Typography>
                <Typography sx={styles.email}>{currentUser.email}</Typography>
              </div>
            </Box>
            <IconButton onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </div>
        </BurgerMenu>
      ) : (
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={menuOpen}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                minWidth: "243px",
                borderRadius: "8px",
                padding: "8px",
                gap: "8px",
              },
            },
          }}
        >
          <Box pl={2} pr={1} py={1}>
            <Typography sx={styles.accountTitle}>{currentUser.name}</Typography>
            <div style={styles.menuContainer}>
              <div style={styles.menuIconText}>
                <UserCheckIcon style={{ marginTop: "8px" }} />
                <Typography sx={styles.email}>{currentUser.email}</Typography>
              </div>
            </div>
          </Box>
          {!blockedCompany && !hasTransactionsLimit && (
            <MenuItem
              sx={{ ...styles.accountOptions, marginTop: "0px" }}
              key="accounts"
              onClick={() => {
                setIsCompanySwitchModalVisible(true);
                setMenuOpen(false);
              }}
            >
              <div style={styles.menuContainer}>
                <div style={styles.menuIconText}>
                  <AccountsIcon style={{ marginTop: "8px" }} />
                  <Typography sx={styles.email}>Empresas</Typography>
                </div>
                <SwitchIcon style={{ marginTop: "8px" }} />
              </div>
            </MenuItem>
          )}
          <Divider style={styles.divider} />
          {menuOptions?.map((menuOption) => (
            <MenuItem
              sx={styles.accountOptions}
              key={menuOption.title}
              onClick={menuOption.action}
            >
              <div style={styles.menuContainer}>
                <div style={styles.menuIconText}>
                  {menuOption.icon}
                  {menuOption.title}
                </div>
                {menuOption.endIcon && menuOption.endIcon}
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};
