import { WarningIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import { useNavigate } from "react-router-dom";
import "./debt-list-empty-state.css";

export const DebtListEmptyState = ({
  backToServiceCompanySearch,
  serviceRegistered,
}) => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <WarningIcon />
      <div className="title">
        {serviceRegistered.service
          ? "Estás al día con este servicio."
          : "No encontramos un servicio asociado a este número de referencia."}
      </div>
      <div>
        {serviceRegistered.service
          ? "Hemos registrado el servicio y recibiras las próximas deudas de manera automática."
          : "Puedes buscar la deuda de otro servicio."}
      </div>
      <div className="button-container">
        {serviceRegistered.service ? (
          <PrimaryButton
            text="Ir a Agenda"
            action={() => navigate("/services/agenda")}
            width="150px"
          />
        ) : (
          <PrimaryButton
            text="Revisar información"
            action={backToServiceCompanySearch}
            width="150px"
          />
        )}
      </div>
    </div>
  );
};
