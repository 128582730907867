import React, { useContext, useState, useRef } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesAgendaColumns, subMenuItems } from "utils";
import { renderRowEndButtons } from "./renderRowEndButtons";
import { ChangeServiceAliasModal } from "commons";
import { CHANGE_SERVICE_COMPANY_ALIAS_MODAL } from "commons/modals/keys";
import { UiContext } from "contexts";
import { ServicesCreatePaymentModal } from "../ServicesPaymentScreen/ServicesCreatePaymentModal";
import { MassiveCreateResultModal } from "../ServicesPaymentScreen/MassiveCreateModal/MassiveCreateResultModal"
import { useMediaQuery } from "react-responsive";

export const ServicesAgendaScreen = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const url = "/services/agenda";
  const [refreshId, setRefreshId] = useState(0);
  const refreshTable = () => setRefreshId((id) => id + 1);
  const [query, setQuery] = useState("");
  const autocomplete = () =>
    renderAutocomplete({
      isMobile: isMobile,
      inputValue: query,
      searchOptions: [],
      onTableSearchSelected: () => {},
      getSearchOptions: setQuery,
    });
  const queries = {
    ...(query && { query }),
    ...(refreshId && { refreshId }),
  };
  const { state, hooks } = useContext(UiContext);
  const { openModalKeys, openModalProps } = state;
  const csvImportButton = useRef(null);
  const [isManualCreateOpen, setIsManualCreateOpen] = useState(false);
  const openManualCreate = () => setIsManualCreateOpen(true);
  const onClose = () => setIsManualCreateOpen(false);
  const openMassiveCreate = () => csvImportButton.current.click();
  return (
    <>
      <ListPage
        url={url}
        preventRequest={() => query.length > 1 && query.length < 3}
        queries={queries}
        renderRowEndButtons={(row, setOpenModalKeys, setClosedModalKeys) =>
          renderRowEndButtons(
            row,
            setOpenModalKeys,
            setClosedModalKeys,
            refreshTable
          )
        }
        getTableData={(response) => response}
        section="services"
        pageTitle="Agenda"
        tableColumns={servicesAgendaColumns}
        pageEmptyState={
          <ServicesEmptyState
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
            currentScreenTitle={'Agenda'}
          />
        }
        subMenuItems={subMenuItems}
        renderAutocomplete={autocomplete}
      />
      <ChangeServiceAliasModal
        openModalProps={openModalProps}
        visible={openModalKeys.includes(CHANGE_SERVICE_COMPANY_ALIAS_MODAL)}
        setClosedModalKeys={hooks.setClosedModalKeys}
      />
      <ServicesCreatePaymentModal
        visible={isManualCreateOpen}
        onClose={onClose}
      />
      <MassiveCreateResultModal csvImportButton={csvImportButton} />
    </>
  );
};
