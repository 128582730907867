import { Box, Stack, Typography } from "@mui/material";
import { EmptyStateSearchIcon } from "assets";
import { ServicesCreateButtons } from "./ServicesCreateButtons";
import { PaymentsContext } from "contexts";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { StatusFilters } from "../../ServicesPaymentScreen/Components/StatusFilters.js";

const styles = {
  box: {
    backgroundColor: "#F9FAFB",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
  },
  stack: {
    alignItems: "center",
    padding: "32px 0px",
    justifyContent: "center",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#101828",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
};

const messages = {
  noServices: {
    title: "Todavía no tienes servicios agendados",
    subtitle: "Una vez que realices un pago o agendes un servicio, te avisaremos los próximos vencimientos"
  },
  pending: {
    title: "No tienes servicios por pagar",
    subtitle: "Cuando tengas servicios pendientes de pago aparecerán aquí"
  },
  paid: {
    title: "No tienes servicios pagados",
    subtitle: "Los servicios que pagues aparecerán en esta sección"
  },
  expired: {
    title: "No tienes servicios vencidos",
    subtitle: "Los servicios con deudas vencidas aparecerán en esta sección"
  }
};

export const ServicesEmptyState = ({
  openManualCreate,
  openMassiveCreate,
  currentScreenTitle,
  hasScheduledServices = false,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const {
    statusSelected,
    setStatusSelected,
  } = useContext(PaymentsContext);

  const onStatusSelectedChanged = (status) => {
    setStatusSelected(status);
  };

  const getMessage = () => {
    if (!hasScheduledServices) return messages.noServices;
    return messages[statusSelected] || messages.noServices;
  };

  return (
    <div
      className="layout-container"
      style={{
        margin: "auto",
      }}
    >
      {hasScheduledServices && (
        <>
          {isMobile ? (
            <StatusFilters
              statusSelected={statusSelected}
              setStatusSelected={onStatusSelectedChanged}
              isMobile={isMobile}
            />
          ) : (
            <div className="invoices-data-filters">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: "0px 2.5%",
                  justifyContent: "space-between",
                  flex: "0 0 auto",
                }}
              >
                <StatusFilters
                  statusSelected={statusSelected}
                  setStatusSelected={onStatusSelectedChanged}
                  isMobile={isMobile}
                />
              </Box>
            </div>
          )}
        </>
      )}
      <div style={styles.titleContainer}>
        <Typography sx={{ ...styles.title, marginBottom: "12px" }} noWrap>
          {currentScreenTitle}
        </Typography>
      </div>
      <Box sx={styles.box}>
        <Stack spacing={2} p={4} sx={styles.stack}>
          <EmptyStateSearchIcon />
          <Box>
            <Typography sx={styles.emptyStateTitle}>
              {getMessage().title}
            </Typography>
            <Typography sx={styles.emptyStateSubtitle}>
              {getMessage().subtitle}
            </Typography>
          </Box>
          <ServicesCreateButtons
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
          />
        </Stack>
      </Box>
    </div>
  );
};
