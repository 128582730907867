import * as amplitude from "@amplitude/analytics-browser";
import { PayanaLogo } from "../../../assets";
import { AccountMenu } from "./components";
import { Link } from "react-router-dom";
import { CustomLink } from "../components";
import { UserContext } from "../../../contexts";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { styles } from "./styles";
import TrialMessage from "./TrialMessage/TrialMessage";
import { SERVICES } from "constants";

export const Navbar = ({
  isLoadingLayout,
  showMenuOptions = true,
  showUserMenu = true,
}) => {
  const {
    getMe,
    currentUser,
    currentCompany,
    comissionRetentionIsEnabled,
    comissionRetentionsIsEmpty,
    hasSiigoCredentials,
  } = useContext(UserContext);

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const navBarStyle = () => {
    return currentUser.backoffice_user
      ? styles.containerFullWidth
      : styles.container;
  };

  const showTrialMessage =
    currentCompany?.id &&
    comissionRetentionIsEnabled &&
    comissionRetentionsIsEmpty &&
    !currentCompany?.suscribed;

  return (
    <nav className="nav" style={{ marginBottom: !isLoadingLayout ? 2 : 0 }}>
      {isMobile ? (
        <div className="navMobile" style={styles.containerFullWidth}>
          <div className="navDiv">
            <Link
              to={
                currentUser.backoffice_user
                  ? "/backoffice-receipts"
                  : "/payments/invoices"
              }
              style={{ padding: 0, margin: 0 }}
            >
              <PayanaLogo />
            </Link>
          </div>
          {showMenuOptions && <AccountMenu />}
        </div>
      ) : (
        <div
          className={
            !currentUser.backoffice_user ? "layout-container navDesktop" : ""
          }
          style={navBarStyle()}
        >
          <div className="navDiv">
            {showMenuOptions ? (
              <Link
                to={
                  currentUser.backoffice_user
                    ? "/backoffice-receipts"
                    : "/payments/invoices"
                }
              >
                <PayanaLogo />
              </Link>
            ) : (
              <PayanaLogo />
            )}
            {currentUser.backoffice_user ? (
              <ul>
                <CustomLink to="/backoffice-receipts">Soportes</CustomLink>
                <CustomLink to="/backoffice-receipts-services">
                  Soportes-Servicios
                </CustomLink>
                <CustomLink to="/backoffice-subscription">
                  Suscripciones
                </CustomLink>
              </ul>
            ) : showMenuOptions ? (
              <ul>
                <CustomLink to="/payments">Proveedores</CustomLink>
                <CustomLink to="/payroll">Nómina</CustomLink>
                <CustomLink to="/services" isNew={true}>
                  Servicios
                </CustomLink>
                <CustomLink to="/collections">Cobros</CustomLink>
                <CustomLink
                  to="/transactions"
                  onClick={() =>
                    amplitude.logEvent("VIEW_TRANSACTIONS_TAB", {
                      user_id: currentUser.id,
                    })
                  }
                >
                  Transacciones
                </CustomLink>
              </ul>
            ) : null}
          </div>

          {(showMenuOptions || !showUserMenu) &&
            (!showMenuOptions || showUserMenu) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <AccountMenu />
              </div>
            )}
        </div>
      )}
    </nav>
  );
};
