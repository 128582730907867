import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./system";
import {
  ExpandCollectionModal,
  ExpandCustomerCollectionModal,
  ExpandInvoiceModal,
  Layout,
  NotifyWompiModal,
  ExpandCausationModal,
  ExpandAssistedExpenses,
} from "./commons";
import {
  InvoicesScreen,
  CollectionsScreen,
  EmployeesScreen,
  PaymentsScreen,
  PayrollScreen,
  PayrollTransactionsScreen,
  SalariesScreen,
  SuppliersScreen,
  TransactionsScreen,
  SettingsScreen,
  BOServicesReceiptsScreen,
  Login,
  Signup,
  ReceiptsScreen,
  BOReceiptsScreen,
  LoginBubbleScreen,
  CustomersScreen,
  CollectionsTransactionsScreen,
  CollectionsRedirectScreen,
  CustomerPayScreen,
  CausationScreen,
  StorybookScreen,
  ErrorScreen,
  Onboarding,
  Companies,
  ManagerWelcome,
  TransactionDetails,
  InvoicesTransactionsScreen,
  BOSubscription,
  ReconciliationsScreen,
  ReconciliationsComingSoonScreen,
  ServicesPaymentScreen,
  ServicesAgendaScreen,
  ServicesTransactionsScreen,
} from "./screens";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  UserProvider,
  PaymentsProvider,
  PayrollProvider,
  TransactionsProvider,
  BOMasterProvider,
  InterruptedServiceProvider,
  CollectionsProvider,
  CompanySwitchProvider,
  CausationProvider,
  ReconciliationsProvider,
} from "./contexts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RestorePassword } from "./screens/RestorePassword/RestorePassword";
import { ChangePassword } from "./screens/ChangePassword/ChangePassword";
import { CreatePassword } from "./screens/CreatePassword/CreatePassword";
import { UiProvider } from "./contexts/UiContext";
import { hasSession } from "./utils";
import { IsAllowedTo } from "utils/IsAllowedTo";
import { APP_ENV_PRODUCTION } from "constants";

const Authenticated = ({ render }) => {
  return hasSession() ? render : <Navigate to="/login" />;
};

const RedictionByCondition = ({ condition, children, to }) => {
  return condition ? children : <Navigate to={to} />;
};

const IfAuthenticatedRedirect = ({ to, render }) => {
  return !hasSession() ? render : <Navigate to={to} />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <UiProvider>
          <UserProvider>
            <PaymentsProvider>
              <PayrollProvider>
                <CollectionsProvider>
                  <TransactionsProvider>
                    <BOMasterProvider>
                      <InterruptedServiceProvider>
                        <CompanySwitchProvider>
                          <ReconciliationsProvider>
                            <Layout>
                              <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                              />
                              <div>
                                <Routes>
                                  <Route
                                    path="/"
                                    element={<Navigate to="/login" />}
                                  />
                                  <Route
                                    path="*"
                                    element={<Navigate to="/error" />}
                                  />
                                  <Route
                                    path="/login"
                                    element={
                                      <IfAuthenticatedRedirect
                                        to="/payments/invoices"
                                        render={<Login />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/create-password"
                                    element={<CreatePassword />}
                                  />
                                  <Route
                                    path="/restore-password"
                                    element={<RestorePassword />}
                                  />
                                  <Route
                                    path="/change-password"
                                    element={<ChangePassword />}
                                  />
                                  <Route
                                    path="/login-bubble"
                                    element={<LoginBubbleScreen />}
                                  />
                                  <Route path="/signup" element={<Signup />} />
                                  <Route
                                    path="/companies"
                                    element={<Companies />}
                                  />
                                  <Route
                                    path="/welcome"
                                    element={<ManagerWelcome />}
                                  />
                                  <Route
                                    path="/sales-team"
                                    element={<Signup />}
                                  />
                                  <Route
                                    path="/payments"
                                    element={
                                      <Authenticated
                                        render={<PaymentsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/onboarding"
                                    element={
                                      <Authenticated render={<Onboarding />} />
                                    }
                                  />
                                  <Route
                                    path="/payments/invoices"
                                    element={
                                      <Authenticated
                                        render={<InvoicesScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/invoices/:id"
                                    element={
                                      <Authenticated
                                        render={<ExpandInvoiceModal />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/suppliers"
                                    element={
                                      <Authenticated
                                        render={<SuppliersScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/transactions"
                                    element={
                                      <Authenticated
                                        render={<InvoicesTransactionsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/receipts"
                                    element={
                                      <Authenticated
                                        render={<ReceiptsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/collections"
                                    element={
                                      <Authenticated
                                        render={<CollectionsRedirectScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/collections/index"
                                    element={
                                      <Authenticated
                                        render={<CollectionsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/collections/index/:id"
                                    element={
                                      <Authenticated
                                        render={<ExpandCollectionModal />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/collections/customers"
                                    element={
                                      <Authenticated
                                        render={<CustomersScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/collections/transactions"
                                    element={
                                      <Authenticated
                                        render={
                                          <CollectionsTransactionsScreen />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/pay"
                                    element={<CustomerPayScreen />}
                                  />
                                  <Route
                                    path="/pay/:id"
                                    element={<ExpandCustomerCollectionModal />}
                                  />
                                  <Route
                                    path="/payroll"
                                    element={
                                      <Authenticated
                                        render={<PayrollScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payroll/salaries"
                                    element={
                                      <Authenticated
                                        render={<SalariesScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payroll/employees"
                                    element={
                                      <Authenticated
                                        render={<EmployeesScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payroll/transactions"
                                    element={
                                      <Authenticated
                                        render={<PayrollTransactionsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/expenses/:document_number/:receipt_id"
                                    element={
                                      <Authenticated
                                        render={<ExpandAssistedExpenses />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/causation"
                                    element={
                                      <Authenticated
                                        render={<CausationScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/storybook"
                                    element={
                                      <RedictionByCondition
                                        condition={
                                          process.env.REACT_APP_ENVIRONMENT !==
                                          APP_ENV_PRODUCTION
                                        }
                                        to="/payments/invoices"
                                      >
                                        <Authenticated
                                          render={<StorybookScreen />}
                                        />
                                      </RedictionByCondition>
                                    }
                                  />
                                  <Route
                                    path="/payments/causation"
                                    element={
                                      <Authenticated
                                        render={<CausationScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/services"
                                    element={
                                      <Authenticated
                                        render={
                                          <Navigate to="/services/payments" />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/services/payments"
                                    element={
                                      <Authenticated
                                        render={<ServicesPaymentScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/services/agenda"
                                    element={
                                      <Authenticated
                                        render={<ServicesAgendaScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/services/transactions"
                                    element={
                                      <Authenticated
                                        render={<ServicesTransactionsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/causation/:id"
                                    element={
                                      <Authenticated
                                        render={
                                          <CausationProvider>
                                            <ExpandCausationModal />
                                          </CausationProvider>
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/causation/:id"
                                    element={
                                      <Authenticated
                                        render={
                                          <CausationProvider>
                                            <ExpandCausationModal />
                                          </CausationProvider>
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/payments/causation/new"
                                    element={
                                      <Authenticated
                                        render={
                                          <CausationProvider>
                                            <ExpandCausationModal />
                                          </CausationProvider>
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/transactions"
                                    element={
                                      <Authenticated
                                        render={<TransactionsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/transactions/:id"
                                    element={
                                      <Authenticated
                                        render={<TransactionDetails />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/reconciliations"
                                    element={
                                      <Authenticated
                                        render={<ReconciliationsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/reconciliations/coming-soon"
                                    element={
                                      <Authenticated
                                        render={
                                          <ReconciliationsComingSoonScreen />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/settings"
                                    element={
                                      <Authenticated
                                        render={<SettingsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/notify-transaction"
                                    element={
                                      <Authenticated
                                        render={<NotifyWompiModal />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/backoffice-receipts"
                                    element={
                                      <Authenticated
                                        render={<BOReceiptsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/backoffice-receipts-services"
                                    element={
                                      <Authenticated
                                        render={<BOServicesReceiptsScreen />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/backoffice-subscription"
                                    element={
                                      <Authenticated
                                        render={<BOSubscription />}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/error"
                                    element={<ErrorScreen />}
                                  />
                                </Routes>
                              </div>
                            </Layout>
                          </ReconciliationsProvider>
                        </CompanySwitchProvider>
                      </InterruptedServiceProvider>
                    </BOMasterProvider>
                  </TransactionsProvider>
                </CollectionsProvider>
              </PayrollProvider>
            </PaymentsProvider>
          </UserProvider>
        </UiProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
